import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { ClipLoader } from 'react-spinners';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { FaArrowLeft } from 'react-icons/fa';

const UserBlogPost = () => {
  const { blogId, postId } = useParams();
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [themeColor, setThemeColor] = useState('#000000');
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [originalUrl, setOriginalUrl] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (!blogId || !postId) {
          throw new Error("Blog ID or Post ID is undefined");
        }

        const [userId, actualBlogId] = blogId.split('-');
        const postDocPath = `users/${userId}/blogs/${actualBlogId}/posts/${postId}`;
        const blogDocPath = `users/${userId}/blogs/${actualBlogId}`;
        console.log(`Fetching post from document path: ${postDocPath}`);

        const postDocRef = doc(db, postDocPath);
        const postDoc = await getDoc(postDocRef);

        const blogDocRef = doc(db, blogDocPath);
        const blogDoc = await getDoc(blogDocRef);

        if (blogDoc.exists()) {
          const blogData = blogDoc.data();
          const color = blogData.colors ? blogData.colors[0] : '#000000';
          setThemeColor(color);
          setTextColor(getTextColor(color));
          setOriginalUrl(blogData.websiteUrl || '');
        }

        if (!postDoc.exists()) {
          setError("No such post found.");
          return;
        }

        setPost(postDoc.data());
      } catch (err) {
        console.error('Error fetching post:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (blogId === 'test-long-random-string') {
      const dummyPost = {
        title: 'Lorem Ipsum Dolor Sit Amet',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
      };
      setPost(dummyPost);
      setLoading(false);
    } else {
      fetchPost();
    }
  }, [blogId, postId]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  const getTextColor = (bgColor) => {
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 186 ? '#000000' : '#FFFFFF';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white">
        <ClipLoader color="#4A90E2" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return <div className="max-w-4xl mx-auto px-4 py-8 text-red-500">{error}</div>;
  }

  if (!post) {
    return <div className="max-w-4xl mx-auto px-4 py-8">Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-white text-black">
      <div className="w-full h-32 flex items-center justify-center text-center px-4 relative" style={{ backgroundColor: themeColor, color: textColor }}>
        <button
          className="absolute left-4 px-4 py-2 bg-white text-black rounded hover:bg-gray-200"
          onClick={() => navigate(`/${blogId}`)}
        >
          <FaArrowLeft />
        </button>
        <h1 className="text-4xl font-bold">{stripLeadingHash(post.title)}</h1>
        {originalUrl && (
          <a
            href={originalUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute right-4 bg-white text-black py-2 px-4 rounded hover:bg-gray-200 transition duration-300 whitespace-nowrap"
          >
            Back to Site
          </a>
        )}
      </div>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="prose prose-lg text-black">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {post.content || ''}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default UserBlogPost;
