import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import { ClipboardCopyIcon } from '@heroicons/react/outline';

const DnsDetails = () => {
  const { currentUser } = useAuth();
  const [dnsInstructions, setDnsInstructions] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { blogId } = useParams();
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      if (currentUser) {
        try {
          const [userId, blogDocId] = blogId.split('-');
          console.log('User ID:', userId, 'Blog ID:', blogDocId);

          if (userId !== currentUser.uid) {
            setError('Invalid blog ID');
            setLoading(false);
            return;
          }

          const blogRef = doc(db, 'users', userId, 'blogs', blogDocId);
          const blogDoc = await getDoc(blogRef);

          if (blogDoc.exists()) {
            console.log('Blog found:', blogDoc.data());
            const blogData = blogDoc.data();
            const domain = blogData.websiteUrl.replace(/^https?:\/\//, '').replace(/\/$/, '');
            const txtRecordValue = `blog_identifier=${blogId}`;

            const instructions = `To connect your site, please set up the following DNS records:

Type: CNAME
Name: blog
Content: ${domain}

Type: TXT
Name: _blogid.blog.${domain}
Content: "${txtRecordValue}"

TTL: 60`;

            setDnsInstructions(instructions);
          } else {
            console.log('Blog not found for ID:', blogId);
            setError('Blog not found');
          }
        } catch (error) {
          console.error('Error fetching blog details:', error);
          setError('Failed to fetch blog details. Please check your permissions.');
        }
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [currentUser, blogId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(dnsInstructions)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };

  return (
    <>
    <Navbar />
    <div className="min-h-screen pt-16 bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">DNS Setup Instructions</h1>
          <p className="mt-2 text-gray-600">Follow these steps to connect your blog to your domain</p>
        </div>

        {loading ? (
          <div className="flex items-center justify-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-violet-600"></div>
          </div>
        ) : error ? (
          <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-600">
            {error}
          </div>
        ) : (
          <div className="bg-white rounded-2xl shadow-sm border border-gray-100">
            <div className="p-6 border-b border-gray-100">
              <h2 className="text-lg font-semibold text-gray-900">DNS Records</h2>
              <p className="mt-1 text-sm text-gray-600">Add these records to your domain's DNS settings</p>
            </div>
            
            <div className="p-6">
              <div className="relative">
                <textarea
                  readOnly
                  value={dnsInstructions}
                  className="w-full h-48 bg-gray-50 text-gray-900 p-4 rounded-xl border border-gray-200 font-mono text-sm resize-none focus:ring-2 focus:ring-violet-500 focus:border-transparent"
                />
                <button
                  onClick={copyToClipboard}
                  className={`absolute top-4 right-4 p-2 rounded-lg transition-all ${
                    copySuccess 
                      ? 'bg-green-100 text-green-600' 
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  <ClipboardCopyIcon className="h-5 w-5" />
                </button>
              </div>
              
              {copySuccess && (
                <p className="mt-2 text-sm text-green-600">
                  Copied to clipboard!
                </p>
              )}
              
              <div className="mt-6 bg-amber-50 border border-amber-200 rounded-lg p-4">
                <h3 className="text-sm font-medium text-amber-800">Important Note</h3>
                <p className="mt-1 text-sm text-amber-700">
                  DNS changes may take up to 48 hours to propagate globally. However, they often take effect within a few hours.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default DnsDetails;