// src/Posts.js

import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { DocumentTextIcon, SparklesIcon } from '@heroicons/react/outline';

const Posts = () => {
  const { currentUser } = useAuth();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      if (currentUser) {
        try {
          const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
          const blogsSnapshot = await getDocs(blogsRef);
          const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
          
          const allPosts = [];
          for (const blog of blogsList) {
            const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
            const postsSnapshot = await getDocs(postsRef);
            const blogPosts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), blogId: blog.id }));
            allPosts.push(...blogPosts);
          }

          setPosts(allPosts);
        } catch (error) {
          setError('Failed to fetch posts. Please check your permissions.');
        }
        setLoading(false);
      }
    };

    fetchPosts();
  }, [currentUser]);

  const handleGeneratePosts = async () => {
    setGenerating(true);
    setError('');
    try {
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

      if (blogsList.length > 0) {
        const blog = blogsList[0]; // Assuming you want to generate posts for the first blog

        const existingTitles = [];
        const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
        const postsSnapshot = await getDocs(postsRef);
        postsSnapshot.docs.forEach(doc => {
          existingTitles.push(doc.data().title);
        });

        const response = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/generate-posts', {
          url: blog.data.websiteUrl,
          uid: currentUser.uid,
          num_posts: 2,
          existing_titles: existingTitles,
        });

        const newPosts = response.data;

        for (const postContent of newPosts) {
          await addDoc(collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts'), {
            title: postContent.split('\n')[0].replace('Title: ', ''),
            content: postContent.split('\n').slice(1).join('\n'),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
        }

        // Refresh the posts list
        const refreshedPostsSnapshot = await getDocs(postsRef);
        const refreshedBlogPosts = refreshedPostsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), blogId: blog.id }));
        setPosts(refreshedBlogPosts);

      } else {
        setError('No blogs found to generate posts.');
      }

    } catch (error) {
      console.error('Error generating posts:', error); // Log the detailed error
      setError('Failed to generate posts. Please try again.');
    }
    setGenerating(false);
  };

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  return (
    <>
    <Navbar />
    <div className="min-h-screen pt-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900">Your Blog Posts</h1>
          <p className="mt-2 text-gray-600">Manage and generate new content for your blog</p>
        </div>

        {currentUser ? (
          loading ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-violet-600"></div>
            </div>
          ) : error ? (
            <div className="bg-red-50 border border-red-200 rounded-xl p-4 text-red-600">
              {error}
            </div>
          ) : (
            <div className="space-y-6">
              <div className="flex justify-end">
                <button 
                  onClick={handleGeneratePosts}
                  disabled={generating}
                  className={`inline-flex items-center px-6 py-3 rounded-full ${
                    generating
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700'
                  } transition-all duration-200 shadow-sm`}
                >
                  <SparklesIcon className="h-5 w-5 mr-2" />
                  {generating ? 'Generating...' : 'Generate 2 More Posts'}
                </button>
              </div>

              {posts.length === 0 ? (
                <div className="text-center py-12 bg-white rounded-2xl shadow-sm border border-gray-100">
                  <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <h3 className="mt-2 text-lg font-medium text-gray-900">No posts yet</h3>
                  <p className="mt-1 text-gray-500">Get started by generating your first posts.</p>
                </div>
              ) : (
                <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-50 border-b border-gray-100">
                          <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">Title</th>
                          <th className="px-6 py-4 text-left text-sm font-medium text-gray-900">Date</th>
                          <th className="px-6 py-4 text-right text-sm font-medium text-gray-900">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {posts.map((post, index) => (
                          <tr key={index} className="hover:bg-gray-50 transition-colors">
                            <td className="px-6 py-4 text-sm text-gray-900">
                              {stripLeadingHash(post.title)}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500">
                              {post.createdAt && post.createdAt.toDate().toDateString()}
                            </td>
                            <td className="px-6 py-4 text-right">
                              <Link 
                                to={`/blog/${post.blogId}/post/${post.id}`}
                                className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-violet-600 bg-violet-50 hover:bg-violet-100 transition-colors"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )
        ) : (
          <div className="text-center py-12 bg-white rounded-2xl shadow-sm border border-gray-100">
            <p className="text-gray-600">Please log in to view your posts.</p>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Posts;