// src/StepTwo.js

import React, { useState } from 'react';

const StepTwo = ({ prevStep, handleSubmit, handleChange, values }) => {
  const [error, setError] = useState('');

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name)({ target: { value: checked } });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleChange(name)({ target: { value } });
  };

  const handleFormSubmit = () => {
    if (!values.terms) {
      setError('Please accept the terms and conditions to proceed.');
    } else {
      setError('');
      handleSubmit();
    }
  };

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 text-red-500 p-4 rounded-lg">
          {error}
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Location</label>
        <input
          type="text"
          placeholder="City, Country"
          onChange={handleInputChange}
          name="location"
          value={values.location}
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div className="space-y-4">
        <label className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="marketingConsent"
            name="marketingConsent"
            checked={values.marketingConsent}
            onChange={handleCheckboxChange}
            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
          <span className="text-gray-700">I agree to receive marketing communications</span>
        </label>

        <label className="flex items-center space-x-3">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={values.terms}
            onChange={handleCheckboxChange}
            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            required
          />
          <span className="text-gray-700">
            I agree to the{' '}
            <a href="https://extremedaytrips.com/privacy-policy" target="_blank" className="text-blue-600 hover:text-blue-800">
              privacy policy
            </a>{' '}
            and{' '}
            <a href="https://extremedaytrips.com/terms" target="_blank" className="text-blue-600 hover:text-blue-800">
              terms and conditions
            </a>
          </span>
        </label>
      </div>

      <div className="flex gap-4 pt-4">
        <button
          onClick={prevStep}
          className="w-1/2 bg-white text-blue-600 py-3 rounded-lg font-medium border-2 border-blue-600 hover:bg-blue-50 transition-colors"
        >
          Back
        </button>
        <button
          onClick={handleFormSubmit}
          className="w-1/2 bg-blue-600 text-white py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors"
        >
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
