// src/StepOne.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DOMParser } from 'xmldom';

const StepOne = ({ nextStep, handleChange, values }) => {
  const [error, setError] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    axios.get('/phoneCodes.xml')
      .then(response => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
        const items = xmlDoc.getElementsByTagName('item');
        const codes = Array.from(items).map(item => ({
          country: item.getElementsByTagName('country')[0].textContent,
          countryCode: item.getElementsByTagName('countryCode')[0].textContent
        }));
        setCountryCodes(codes);
        if (!values.countryCode) {
          handleChange('countryCode')({ target: { value: '+44' } });
        }
      })
      .catch(error => console.error('Error fetching country codes:', error));
  }, [handleChange, values.countryCode]);

  const handleNextClick = () => {
    if (!values.firstName || !values.lastName || !values.email || !values.password || !values.phone) {
      setError('Please fill out all fields.');
    } else {
      setError('');
      nextStep();
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">First Name</label>
        <input
          type="text"
          onChange={handleChange('firstName')}
          value={values.firstName}
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Last Name</label>
        <input
          type="text"
          onChange={handleChange('lastName')}
          value={values.lastName}
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Email Address</label>
        <input
          type="email"
          onChange={handleChange('email')}
          value={values.email}
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">Password</label>
        <input
          type="password"
          onChange={handleChange('password')}
          value={values.password}
          className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      <button
        onClick={handleNextClick}
        className="w-full bg-blue-600 text-white py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors"
      >
        Continue
      </button>
    </div>
  );
};

export default StepOne;
