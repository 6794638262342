import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/outline';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [blogs, setBlogs] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updateDns, setUpdateDns] = useState(true);

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Website Traffic',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    const fetchBlogsAndPosts = async () => {
      if (currentUser) {
        try {
          const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
          const blogsSnapshot = await getDocs(blogsRef);
          const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          const fetchMetadata = async (url) => {
            try {
              const response = await fetch(`/.netlify/functions/proxy?url=${encodeURIComponent(url)}`);
              const metadata = await response.json();
              return metadata;
            } catch (error) {
              console.error(`Failed to fetch metadata for ${url}`, error);
              return { title: 'Unknown', description: 'No description available' };
            }
          };

          const blogsWithMetadata = await Promise.all(
            blogsList.map(async (blog) => {
              const metadata = await fetchMetadata(blog.websiteUrl);
              return { ...blog, metadata };
            })
          );

          setBlogs(blogsWithMetadata);

          const allPosts = [];
          for (const blog of blogsList) {
            const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
            const q = query(postsRef, orderBy('createdAt', 'desc'));
            const postsSnapshot = await getDocs(q);
            const blogPosts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), blogId: blog.id }));
            allPosts.push(...blogPosts);
          }

          const sortedPosts = allPosts.sort((a, b) => b.createdAt - a.createdAt).slice(0, 5);
          setPosts(sortedPosts);

        } catch (error) {
          setError('Failed to fetch data. Please check your permissions.');
        }
        setLoading(false);
      }
    };

    fetchBlogsAndPosts();
  }, [currentUser]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <Navbar />
      {updateDns && (
        <div className="bg-yellow-500 text-gray-800 py-2 text-center relative">
          <p className="text-lg font-semibold">Update your DNS settings to connect your site!</p>
          {blogs.length > 0 && (
            <Link to={`/dns-details/${currentUser.uid}-${blogs[0].id}`}>
              <button className="absolute right-4 top-1/2 transform -translate-y-1/2 text-black border border-black py-2 px-4 rounded-md hover:bg-yellow-700 hover:text-white transition duration-300 ease-in-out">
                View Instructions
              </button>
            </Link>
          )}
        </div>
      )}
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Dashboard</h1>
        {currentUser ? (
          loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : blogs.length === 0 ? (
            <div>
              <p>You have no blogs yet.</p>
              <Link to="/add-blog" className="btn btn-primary mt-4">Add Blog</Link>
            </div>
          ) : (
            <div>
              <p className="mb-4">Logged in as: {currentUser.email}</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-700 p-4 rounded-lg shadow-md">
                  <h2 className="text-2xl font-bold mb-4">Your Websites</h2>
                  <div className="space-y-4">
                    {blogs.map((blog, index) => (
                      <div key={index} className="bg-gray-800 p-4 rounded-lg shadow-md mb-4">
                        <h3 className="text-xl font-semibold flex justify-between">
                          {stripLeadingHash(blog.metadata.title)}
                          <Link to={`/edit-blog/${currentUser.uid}-${blog.id}`}>
                            <PencilIcon className="h-5 w-5 text-white" />
                          </Link>
                        </h3>
                        <p>{blog.metadata.description}</p>
                        <a href={blog.websiteUrl} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">{blog.websiteUrl}</a>
                        <Link to={`/${currentUser.uid}-${blog.id}`} className="text-blue-500 underline block mt-2">View Blog</Link>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="bg-gray-700 p-4 rounded-lg shadow-md">
                  <h2 className="text-2xl font-bold mb-4">Website Traffic</h2>
                  <div className="relative h-64">
                    <Line data={data} options={options} />
                  </div>
                  <p className="text-sm text-gray-400 mt-2">* This is dummy data for now.</p>
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg shadow-md mt-8">
                <h2 className="text-2xl font-bold mb-4">Upcoming Posts</h2>
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Scheduled Date</th>
                        <th>Preview</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map((post, index) => (
                        <tr key={index}>
                          <td>{stripLeadingHash(post.title)}</td>
                          <td>{post.createdAt && post.createdAt.toDate().toDateString()}</td>
                          <td>
                            <Link to={`/blog/${currentUser.uid}-${post.blogId}/post/${post.id}`} className="btn btn-sm btn-outline btn-info">View</Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        ) : (
          <p>Please log in to view your dashboard.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
