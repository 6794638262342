// src/Welcome.js

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { Helmet } from 'react-helmet';

const Welcome = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/');
  };

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>Welcome to bloganywhere</title>
        <meta name="description" content="Join bloganywhere to streamline your blog content management across multiple platforms." />
      </Helmet>
      
      <div className="flex min-h-screen">
        {/* Left Column - Branding */}
        <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-blue-600 to-blue-800 p-12 flex-col justify-between">
          <div>
            <h1 className="text-4xl font-bold text-white mb-6">bloganywhere</h1>
            <p className="text-xl text-blue-100 mb-8">Transform your content strategy with our all-in-one blog management platform.</p>
            
            <div className="space-y-6">
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">Seamless multi-platform publishing</p>
              </div>
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">Real-time analytics dashboard</p>
              </div>
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">Smart content optimization tools</p>
              </div>
            </div>
          </div>
          
          <div className="text-blue-100 text-sm">
            <p>Join thousands of successful content creators and businesses</p>
          </div>
        </div>

        {/* Right Column - Welcome Actions */}
        <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
          <div className="w-full max-w-md">
            <button onClick={goBack} className="text-gray-600 mb-8 flex items-center hover:text-gray-900">
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to home
            </button>

            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold text-gray-900">Welcome to bloganywhere</h2>
              <p className="text-gray-600 mt-2">Get started with your content journey today</p>
            </div>

            <div className="space-y-4">
              <button 
                onClick={() => navigate('/login')}
                className="w-full bg-blue-600 text-white py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors"
              >
                Sign in to your account
              </button>

              <button 
                onClick={() => navigate('/signup')}
                className="w-full bg-white text-blue-600 py-3 rounded-lg font-medium border-2 border-blue-600 hover:bg-blue-50 transition-colors"
              >
                Create an account
              </button>

              <p className="text-center text-sm text-gray-600 mt-6">
                By signing up, you agree to our{' '}
                <a href="/terms" className="text-blue-600 hover:underline">Terms of Service</a>
                {' '}and{' '}
                <a href="/privacy" className="text-blue-600 hover:underline">Privacy Policy</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;