// src/PasswordReset.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { app } from '../firebaseConfig';
import { Helmet } from 'react-helmet';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    setMessage('');
    try {
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset link sent! Please check your email.');
      setEmailSent(true);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>Reset Password - bloganywhere</title>
        <meta name="description" content="Reset your bloganywhere account password securely." />
      </Helmet>
      
      <div className="flex min-h-screen">
        {/* Left Column - Branding */}
        <div className="hidden lg:flex lg:w-1/2 bg-gradient-to-br from-blue-600 to-blue-800 p-12 flex-col justify-between">
          <div>
            <h1 className="text-4xl font-bold text-white mb-6">bloganywhere</h1>
            <p className="text-xl text-blue-100 mb-8">No worries! We'll help you get back into your account.</p>
            
            <div className="space-y-6">
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">Secure password reset process</p>
              </div>
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">Quick and easy recovery</p>
              </div>
              <div className="flex items-center space-x-3">
                <svg className="w-6 h-6 text-blue-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                <p className="text-blue-100">24/7 support available</p>
              </div>
            </div>
          </div>
          
          <div className="text-blue-100 text-sm">
            <p>Need help? Contact our support team</p>
          </div>
        </div>

        {/* Right Column - Reset Form */}
        <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
          <div className="w-full max-w-md">
            <button 
              onClick={() => navigate('/login')} 
              className="text-gray-600 mb-8 flex items-center hover:text-gray-900"
            >
              <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to login
            </button>

            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900">Reset your password</h2>
              <p className="text-gray-600 mt-2">
                {emailSent 
                  ? "We've sent you instructions to reset your password" 
                  : "Enter your email and we'll send you reset instructions"}
              </p>
            </div>

            {message && (
              <div className={`p-4 rounded-lg mb-6 ${
                emailSent ? "bg-green-50 text-green-700" : "bg-red-50 text-red-500"
              }`}>
                {message}
              </div>
            )}

            {!emailSent && (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Email Address</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                <button
                  onClick={handlePasswordReset}
                  className="w-full bg-blue-600 text-white py-3 rounded-lg font-medium hover:bg-blue-700 transition-colors"
                >
                  Send Reset Instructions
                </button>
              </div>
            )}

            {emailSent && (
              <div className="text-center">
                <button
                  onClick={() => navigate('/login')}
                  className="text-blue-600 hover:text-blue-800 font-medium"
                >
                  Return to login
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
