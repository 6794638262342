import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import { PencilIcon, ChartBarIcon, CalendarIcon, GlobeAltIcon } from '@heroicons/react/outline';

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [blogs, setBlogs] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updateDns, setUpdateDns] = useState(true);

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Website Traffic',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    const fetchBlogsAndPosts = async () => {
      if (currentUser) {
        try {
          const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
          const blogsSnapshot = await getDocs(blogsRef);
          const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

          const fetchMetadata = async (url) => {
            try {
              const response = await fetch(`/.netlify/functions/proxy?url=${encodeURIComponent(url)}`);
              const metadata = await response.json();
              return metadata;
            } catch (error) {
              console.error(`Failed to fetch metadata for ${url}`, error);
              return { title: 'Unknown', description: 'No description available' };
            }
          };

          const blogsWithMetadata = await Promise.all(
            blogsList.map(async (blog) => {
              const metadata = await fetchMetadata(blog.websiteUrl);
              return { ...blog, metadata };
            })
          );

          setBlogs(blogsWithMetadata);

          const allPosts = [];
          for (const blog of blogsList) {
            const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
            const q = query(postsRef, orderBy('createdAt', 'desc'));
            const postsSnapshot = await getDocs(q);
            const blogPosts = postsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), blogId: blog.id }));
            allPosts.push(...blogPosts);
          }

          const sortedPosts = allPosts.sort((a, b) => b.createdAt - a.createdAt).slice(0, 5);
          setPosts(sortedPosts);

        } catch (error) {
          setError('Failed to fetch data. Please check your permissions.');
        }
        setLoading(false);
      }
    };

    fetchBlogsAndPosts();
  }, [currentUser]);

  const stripLeadingHash = (title) => title.replace(/^#\s*/, '');

  return (
    <>
    <Navbar />
    <div className="min-h-screen pt-16 bg-gray-50">
      {updateDns && (
        <div className="bg-gradient-to-r from-amber-50 to-amber-100 border-b border-amber-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3 flex items-center justify-between">
            <p className="text-amber-800 font-medium">Update your DNS settings to connect your site!</p>
            {blogs.length > 0 && (
              <Link to={`/dns-details/${currentUser.uid}-${blogs[0].id}`}>
                <button className="px-4 py-2 text-sm font-medium text-amber-900 bg-amber-200 rounded-full hover:bg-amber-300 transition-colors">
                  View Instructions
                </button>
              </Link>
            )}
          </div>
        </div>
      )}

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Welcome back, {currentUser?.email}</h1>
          <Link to="/add-blog" className="px-4 py-2 rounded-full bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700 transition-colors">
            Add New Blog
          </Link>
        </div>

        {currentUser ? (
          loading ? (
            <div className="flex items-center justify-center h-64">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-violet-600"></div>
            </div>
          ) : error ? (
            <div className="p-4 bg-red-50 text-red-600 rounded-lg">{error}</div>
          ) : blogs.length === 0 ? (
            <div className="text-center py-12">
              <GlobeAltIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-lg font-medium text-gray-900">No blogs yet</h3>
              <p className="mt-1 text-gray-500">Get started by adding your first blog.</p>
              <Link to="/add-blog" className="mt-6 inline-flex items-center px-4 py-2 rounded-full bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700 transition-colors">
                Add Blog
              </Link>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Websites Section */}
                <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">Your Websites</h2>
                  <div className="space-y-4">
                    {blogs.map((blog, index) => (
                      <div key={index} className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors">
                        <div className="flex items-center justify-between mb-2">
                          <h3 className="font-medium text-gray-900">{stripLeadingHash(blog.metadata.title)}</h3>
                          <Link to={`/edit-blog/${currentUser.uid}-${blog.id}`}>
                            <PencilIcon className="h-5 w-5 text-gray-500 hover:text-violet-600" />
                          </Link>
                        </div>
                        <p className="text-sm text-gray-600 mb-2">{blog.metadata.description}</p>
                        <div className="flex items-center space-x-4">
                          <a href={blog.websiteUrl} className="text-sm text-violet-600 hover:text-violet-700" target="_blank" rel="noopener noreferrer">
                            Visit Website →
                          </a>
                          <Link to={`/${currentUser.uid}-${blog.id}`} className="text-sm text-violet-600 hover:text-violet-700">
                            View Blog →
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Analytics Section */}
                <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6">
                  <h2 className="text-xl font-semibold text-gray-900 mb-4">Website Traffic</h2>
                  <div className="relative h-64">
                    <Line data={{
                      ...data,
                      datasets: [{
                        ...data.datasets[0],
                        borderColor: '#7c3aed',
                        backgroundColor: '#7c3aed',
                      }]
                    }} options={options} />
                  </div>
                  <p className="text-sm text-gray-500 mt-2 italic">* Sample data visualization</p>
                </div>
              </div>

              {/* Upcoming Posts Section */}
              <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Upcoming Posts</h2>
                <div className="overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b border-gray-200">
                        <th className="px-4 py-3 text-left text-sm font-medium text-gray-900">Title</th>
                        <th className="px-4 py-3 text-left text-sm font-medium text-gray-900">Scheduled Date</th>
                        <th className="px-4 py-3 text-right text-sm font-medium text-gray-900">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100">
                      {posts.map((post, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-4 py-3 text-sm text-gray-900">{stripLeadingHash(post.title)}</td>
                          <td className="px-4 py-3 text-sm text-gray-500">
                            {post.createdAt && post.createdAt.toDate().toDateString()}
                          </td>
                          <td className="px-4 py-3 text-right">
                            <Link 
                              to={`/blog/${currentUser.uid}-${post.blogId}/post/${post.id}`}
                              className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-violet-600 bg-violet-50 hover:bg-violet-100 transition-colors"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="text-center py-12">
            <p className="text-gray-600">Please log in to view your dashboard.</p>
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default Dashboard;
