import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import axios from 'axios';
import { collection, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Navbar from './Navbar';
import { SparklesIcon, PencilIcon } from '@heroicons/react/outline';

const CreatePosts = () => {
  const { currentUser } = useAuth();
  const [mode, setMode] = useState('ai'); // 'ai' or 'manual'
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [numPosts, setNumPosts] = useState(2);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState('');

  const handleGeneratePosts = async () => {
    setGenerating(true);
    setError('');
    try {
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

      if (blogsList.length > 0) {
        const blog = blogsList[0];

        const existingTitles = [];
        const postsRef = collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts');
        const postsSnapshot = await getDocs(postsRef);
        postsSnapshot.docs.forEach(doc => {
          existingTitles.push(doc.data().title);
        });

        const response = await axios.post('https://post-generator-4nbyelqcha-ew.a.run.app/generate-posts', {
          url: blog.data.websiteUrl,
          uid: currentUser.uid,
          num_posts: numPosts,
          existing_titles: existingTitles,
        });

        const newPosts = response.data;

        for (const postContent of newPosts) {
          await addDoc(postsRef, {
            title: postContent.split('\n')[0].replace('Title: ', ''),
            content: postContent.split('\n').slice(1).join('\n'),
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
        }

        alert('Posts generated successfully');
      } else {
        setError('No blogs found to generate posts.');
      }
    } catch (error) {
      console.error('Error generating posts:', error);
      setError('Failed to generate posts. Please try again.');
    }
    setGenerating(false);
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    try {
      const blogsRef = collection(db, 'users', currentUser.uid, 'blogs');
      const blogsSnapshot = await getDocs(blogsRef);
      const blogsList = blogsSnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));

      if (blogsList.length > 0) {
        const blog = blogsList[0];

        await addDoc(collection(db, 'users', currentUser.uid, 'blogs', blog.id, 'posts'), {
          title,
          content,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });

        alert('Post created successfully');
        setTitle('');
        setContent('');
      } else {
        setError('No blogs found to create a post.');
      }
    } catch (error) {
      console.error('Error creating post:', error);
      setError('Failed to create post. Please try again.');
    }
  };

  return (
    <>
    <Navbar />
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900">Create New Posts</h1>
          <p className="mt-2 text-gray-600">Generate AI-powered content or write your own posts</p>
        </div>

        <div className="bg-white rounded-2xl shadow-sm border border-gray-100 overflow-hidden">
          <div className="p-6 border-b border-gray-100">
            <div className="flex space-x-4">
              <button
                className={`flex items-center px-6 py-3 rounded-full transition-all ${
                  mode === 'ai'
                    ? 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setMode('ai')}
              >
                <SparklesIcon className="h-5 w-5 mr-2" />
                AI Generator
              </button>
              <button
                className={`flex items-center px-6 py-3 rounded-full transition-all ${
                  mode === 'manual'
                    ? 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setMode('manual')}
              >
                <PencilIcon className="h-5 w-5 mr-2" />
                Manual Editor
              </button>
            </div>
          </div>

          <div className="p-6">
            {mode === 'ai' ? (
              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="numPosts">
                    Number of Posts to Generate
                  </label>
                  <input
                    id="numPosts"
                    type="number"
                    min="1"
                    max="5"
                    value={numPosts}
                    onChange={(e) => setNumPosts(parseInt(e.target.value))}
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-colors"
                  />
                  <p className="mt-2 text-sm text-gray-500">Generate up to 5 posts at once</p>
                </div>

                <button
                  onClick={handleGeneratePosts}
                  disabled={generating}
                  className={`w-full px-6 py-3 rounded-lg ${
                    generating
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700'
                  } transition-all duration-200 shadow-sm flex items-center justify-center`}
                >
                  <SparklesIcon className="h-5 w-5 mr-2" />
                  {generating ? 'Generating Posts...' : 'Generate Posts'}
                </button>
              </div>
            ) : (
              <form onSubmit={handleManualSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="title">
                    Post Title
                  </label>
                  <input
                    id="title"
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-colors"
                    placeholder="Enter your post title"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="content">
                    Post Content
                  </label>
                  <textarea
                    id="content"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    rows="8"
                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-colors resize-none"
                    placeholder="Write your post content here..."
                  />
                </div>

                <button
                  type="submit"
                  className="w-full px-6 py-3 rounded-lg bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700 transition-all duration-200 shadow-sm flex items-center justify-center"
                >
                  <PencilIcon className="h-5 w-5 mr-2" />
                  Create Post
                </button>
              </form>
            )}

            {error && (
              <div className="mt-6 p-4 bg-red-50 border border-red-200 rounded-lg">
                <p className="text-red-600 text-sm">{error}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CreatePosts;
