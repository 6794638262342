import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Navbar = () => {
  const { logout } = useAuth();

  return (
    <nav className="fixed w-full z-50 backdrop-blur-lg bg-white/70 border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          <div className="flex-1">
            <Link to="/dashboard" className="flex items-center space-x-2">
              <span className="text-2xl font-bold bg-gradient-to-r from-violet-600 to-indigo-600 text-transparent bg-clip-text">
                bloganywhe.re
              </span>
            </Link>
          </div>
          
          <div className="flex items-center space-x-6">
            <Link to="/dashboard" className="text-gray-600 hover:text-gray-900 transition-colors">
              Dashboard
            </Link>
            <Link to="/posts" className="text-gray-600 hover:text-gray-900 transition-colors">
              Posts
            </Link>
            <Link to="/profile" className="text-gray-600 hover:text-gray-900 transition-colors">
              Profile
            </Link>
            <Link 
              to="/create-posts" 
              className="px-4 py-2 rounded-full bg-violet-50 text-violet-600 hover:bg-violet-100 transition-colors"
            >
              Add Posts
            </Link>
            <button 
              onClick={logout} 
              className="px-4 py-2 rounded-full bg-gradient-to-r from-violet-600 to-indigo-600 text-white hover:from-violet-700 hover:to-indigo-700 transition-colors"
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
